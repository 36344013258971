<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码/出厂SN码" clearable></el-input>
      <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
      <el-input v-model="queryInfo.poDoc" class="input" placeholder="来源订单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-switch
          style="margin-right: 10px"
          v-model="queryInfo.disabled"
          active-text="查看已失效">
      </el-switch>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
      <el-button type="primary" @click="importBatch = true">批量导入</el-button>
    </el-row>

    <div style="color: orange;padding-bottom:10px;font-size: 12px;line-height: 14px">
      提示:<br/>
      1、物品数量条件只有在未设置资产码/出厂SN码且设置了借出单号和备件编码时才有效。<br/>
      2、物品数量为0表示不判断物品数量，物品数量大于0时，表示数量必须和借出单里该备件编码总数量一致才拦截(借出单下同备件编码多条数据时，是合计数量)。<br/>
      3、拦截结束后，需要手工失效拦截规则。
    </div>
    <el-table :data="ruleList" border stripe>
      <el-table-column align="center" prop="assetSn" label="资产码/出厂SN码" width="220px"></el-table-column>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="220px"></el-table-column>
      <el-table-column align="center" prop="poDoc" label="来源订单号" width="220px"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码" width="120px"></el-table-column>
      <el-table-column align="center" prop="materialCnt" label="备件数量" width="70px"></el-table-column>
      <el-table-column align="center" prop="supplierName" label="供应商" width="200px"></el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="350px"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150px"></el-table-column>
      <el-table-column align="center" prop="updateUserName" label="失效人" width="80px"></el-table-column>
      <el-table-column align="center" prop="updateTime" label="失效时间" width="150px"></el-table-column>
      <el-table-column align="center" label="操作" width="80px" fixed="right">
        <template slot-scope="scope">
          <el-button @click="disableRule(scope.row)" type="danger" size="small" v-if="!scope.row.disabled">失效</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加拦截规则" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="资产码/出厂SN码：">
          <el-input v-model.trim="formData.assetSn" placeholder="请输入资产码/出厂SN码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="借出单号：">
          <el-input v-model.trim="formData.lendOrderCode" placeholder="请输入借出单号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="来源订单号：">
          <el-input v-model.trim="formData.poDoc" placeholder="请输入来源订单号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件数量：" v-if="showCntInput">
          <el-input v-model.trim="formData.materialCnt" type="number" max="10000" min="0" placeholder="请输入备件数量" style="width: 350px"></el-input>
        </el-form-item>
        <el-form-item label="供应商：">
          <el-select v-model="formData.supplierCode" placeholder="请选择供应商" filterable style="width: 350px">
            <el-option
                v-for="item in supplierList"
                :key="item.supplierCode"
                :label="item.supplierName"
                :value="item.supplierCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model.trim="formData.remark" type="textarea" maxlength="500" placeholder="请输入备注" style="width: 350px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" :loading="loading" @click="addRule">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量导入" :visible.sync="importBatch" :close-on-click-modal="false" width="800px" :before-close="importClose">
      <ImportComponent template-url="template/ruleIntercept.xlsx" upload-url="ruleIntercept/importBatch"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importClose">关闭</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent.vue";

export default {
  name: "RuleIntercept",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        materialCode: '',
        supplierCode: '',
        lendOrderCode: '',
        disabled: false,
        poDoc: '',
        assetSn: '',
        pageNo: 1
      },
      formData: {
        materialCode: '',
        materialCnt: 0,
        supplierCode: '',
        lendOrderCode: '',
        poDoc: '',
        assetSn: ''
      },
      total: 0,
      pageSize: 0,
      ruleList: [],
      supplierList: [],
      dialogVisible: false,
      loading: false,
      importBatch: false
    }
  },
  computed: {
    showCntInput: function () {
      //没有资产码且有借出单号和备件编码时，物品数量有效
      return !this.formData.assetSn && this.formData.lendOrderCode && this.formData.materialCode;
    }
  },
  created() {
    this.search();
    this.querySupplier();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('ruleIntercept/queryRule', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.ruleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    async querySupplier() {
      const {data: res} = await this.$axios.get('busSupplier/list');
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.supplierList = res.data || []
    },
    disableRule(row) {
      this.$confirm('确定失效吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleIntercept/disableRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("失效成功");
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.formData.assetSn && !this.formData.lendOrderCode && !this.formData.poDoc && !this.formData.materialCode && !this.formData.supplierCode) {
        return this.$message.error('拦截条件至少输入一项');
      }

      if (!this.showCntInput || !this.formData.materialCnt) {
        this.formData.materialCnt = 0
      }
      this.loading = true
      const {data: res} = await this.$axios.post('ruleIntercept/addRule', this.formData);
      this.loading = false
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.search();
      this.handleClose()
      this.formData.assetSn = ''
      this.formData.lendOrderCode = ''
      this.formData.poDoc = ''
      this.formData.materialCode = ''
      this.formData.supplierCode = ''
      this.formData.materialCnt = 0
    },
    handleClose() {
      this.dialogVisible = false;
    },
    importClose() {
      this.search();
      this.importBatch = false;
    },
  }
}
</script>

<style scoped>
.input {
  width: 200px;
  margin-right: 10px;
}
</style>